import { useEffect, useRef, useState } from "react";
import useScreenSize from "../../hooks/useScreenSize";
import SecondaryButton from "../buttons/SecondaryButton";

const FeePaymentWithIyzico = (props) => {
  const screenSize = useScreenSize();
  const ref = useRef();
  const absRef = useRef();
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const requestBody = {
    locale: "en",
    conversationId: "sampleConversationId",
    price: "5.2",
    basketId: "B67832",
    paymentGroup: "PRODUCT",
    buyer: {
      id: "BY789",
      name: "Higher",
      surname: "Faster",
      identityNumber: "74300864791",
      email: "stronger@implementation.com",
      gsmNumber: "+905555434332",
      registrationAddress: "Nidakule Göztepe, Merdivenköy Mah. Bora Sok. No:1",
      city: "Istanbul",
      country: "Turkey",
      ip: "85.34.78.112",
    },
    shippingAddress: {
      address: "Nidakule Göztepe, Merdivenköy Mah. Bora Sok. No:1",
      contactName: "Jane Doe",
      city: "Istanbul",
      country: "Turkey",
    },
    billingAddress: {
      address: "Nidakule Göztepe, Merdivenköy Mah. Bora Sok. No:1",
      contactName: "Jane Doe",
      city: "Istanbul",
      country: "Turkey",
    },
    basketItems: [
      {
        id: "BI101",
        price: "5.2",
        name: "Binocular",
        category1: "Category 1",
        itemType: "PHYSICAL",
      },
    ],
    callbackUrl: "https://turkannelerdernegi.org/",
    currency: "TRY",
    paidPrice: "5.2",
    paymentSource: "zooz",
    enabledInstallments: [2, 3],
  };

  useEffect(() => {
    if (!ref) return;
    if (!ref.current) return;
    if (!absRef) return;
    if (!absRef.current) return;
    setWidth(ref.current.offsetWidth);
    setHeight(ref.current.offsetHeight);
  }, [ref, absRef, screenSize]);

  const RenderIyzicoContent = () => {
    return (
      <div className="flex flex-col justify-center items-center">
        <div className="flex flex-col items-center">
          <p>Ödemeniz iyzico güvencesi ile alınacaktır.</p>
          <div className="w-[70px]">
            <img className="w-full h-full" src="/images/iyzico.png" />
          </div>
        </div>
      </div>
    );
  };

  const handleOnChange = () => {};
  return (
    <div className="p-2 lg:p-8" id="fee">
      <div className="relative" ref={ref}>
        <div className="hidden md:flex flex-row items-center justify-center">
          <div className="w-[250px]">
            <div className="flex flex-col justify-center item-center">
              <div className="w-[220px]">
                <img className="w-full h-full" src="/images/credit-card.png" />
              </div>
            </div>
          </div>
          <div className="hidden md:block flex-1">
            <div className="h-full flex flex-col justify-center gap-y-2">
              <p className="text-xl font-bold">AİDAT ÖDEME & BAĞIŞ</p>
              <p>
                Türk Anneler Derneği üyeleri, aidat ödemelerini ve bağışlarını
                IYZICO güvencesiyle aşağıdaki linki kullanarak yapabilirler.
              </p>
              <p className="font-bold">
                Bağış ücretimiz 200₺ ‘ dir. Asağıdaki butona basarak bağış
                yapabilirsiniz.
              </p>
            </div>
          </div>
          <div className="w-[150px]">
            <div className="flex flex-col justify-center item-center">
              <div className="w-[120px]">
                <img className="w-full h-full" src="/logos/logo.png" />
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col md:hidden ">
          <div className="flex-1 flex flex-row items-center justify-center">
            <div className="flex-1 flex flex-row justify-center item-center">
              <div className="w-[120px]">
                <img className="w-full h-full" src="/images/credit-card.png" />
              </div>
            </div>

            <div className="flex-1 flex flex-row justify-center item-center">
              <div className="w-[120px]">
                <img className="w-full h-full" src="/logos/logo.png" />
              </div>
            </div>
          </div>
          <div className="h-full flex flex-col justify-center gap-y-2">
            <p className="text-xl font-bold">AİDAT ÖDEME & BAĞIŞ</p>
            <p>
              Türk Anneler Derneği üyeleri, aidat ödemelerini ve bağışlarını
              IYZICO güvencesiyle aşağıdaki linki kullanarak yapabilirler.
            </p>
            <p className="font-bold">
              Bağış ücretimiz 200₺ ‘ dir. Asağıdaki butona basarak bağış
              yapabilirsiniz.
            </p>
          </div>
        </div>

        <div className="my-5 md:my-10 lg:my-0 flex flex-row justify-center items-center">
          <SecondaryButton title="Ödeme yap" onClick={() => {}} />
        </div>
      </div>
    </div>
  );
};

export default FeePaymentWithIyzico;
