import { useEffect, useState } from "react";
import BaseModal from "../modals/BaseModal";
import LandingTitle from "../titles/LandingTitle";
import HorizontalDivider from "../dividers/HorizontalDivider";
import CardWithRightShadow from "../cards/CardWithRightShadow";

const Announcement = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedYear, setSelectedYear] = useState(2024);
  const [selectedEventIndex, setSelectedEventIndex] = useState(0);
  const array = [1, 2, 3, 4, 5];

  useEffect(() => {
    setSelectedEventIndex(0);
  }, [selectedYear]);

  const FullItem = (title, description) => {
    return (
      <div
        className="h-full p-3 md:p-6 lg:p-9 rounded-3xl"
        style={{
          backgroundImage: "url(./images/announcement-full.png)",
          backgroundSize: "cover",
        }}
      >
        <div className="h-full text-white flex flex-col justify-center items-center text-center md:text-start gap-y-2 md:gap-y-3 lg:gap-y-5">
          <div className="w-[40px] md:w-[80px] lg:w-[120px]">
            <img className="w-full h-full" src="/logos/logo.png" />
          </div>
          <p className="font-bold text-sm md:text-base">{title}</p>
          <p className="hidden md:block opacity-50 text-sm md:text-base">
            {description}
          </p>
          <div className="my-5">
            <div className="cursor-pointer">
              <p className="text-yellow underline">Devamını oku</p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const HalfItem = (title, description) => {
    return (
      <div
        className="h-full p-3 md:p-6 lg:p-9 rounded-3xl"
        style={{
          backgroundImage: "url(./images/announcement-half.png)",
          backgroundSize: "cover",
        }}
      >
        <div className="h-full flex flex-row justify-center items-center gap-x-4">
          <div className="flex-1 text-white flex flex-col justify-center items-center text-center md:text-start gap-y-2 md:gap-y-3 lg:gap-y-5">
            <p className="font-bold text-sm md:text-base">{title}</p>
            <p className="hidden md:block opacity-50 text-sm md:text-base">
              {description}
            </p>
            <div className="my-1">
              <div className="cursor-pointer">
                <p className="text-yellow underline">Devamını oku</p>
              </div>
            </div>
          </div>
          <div className="w-[40px] md:w-[80px] lg:w-[120px]">
            <img className="w-full h-full" src="/logos/logo.png" />
          </div>
        </div>
      </div>
    );
  };

  const RenderAnnouncementsModal = () => {
    return (
      <BaseModal
        isOpen={showModal}
        closeModal={() => {
          setShowModal(false);
        }}
        onReturnClick={() => {
          setShowModal(false);
        }}
        title={"DUYURULAR"}
      >
        <div className="h-full flex lg:flex-row flex-col justify-end items-start flex-wrap bg-white">
          <div className="h-8 lg:h-full w-full lg:w-1/6">
            <CardWithRightShadow>
              <div className="h-full flex flex-row lg:flex-col justify-end lg:justify-center items-center gap-x-2 lg:gap-x-0 gap-y-2 flex-wrap mr-8 lg:mr-0">
                {[2024, 2023, 2022].map((item) => (
                  <div
                    className={`${
                      selectedYear === item
                        ? "text-green-dark underline font-bold"
                        : "text-green-light"
                    } cursor-pointer text-center`}
                    onClick={() => {
                      setSelectedYear(item);
                    }}
                  >
                    <p>{item}</p>
                  </div>
                ))}
              </div>
            </CardWithRightShadow>
          </div>
          <div className="w-full block lg:hidden my-3">
            <HorizontalDivider height={300} />
          </div>
          <div className="h-[200px] overflow-y-auto lg:h-full w-full lg:w-1/3">
            <div className="h-full mx-0 lg:mx-2">
              <CardWithRightShadow>
                <div className="h-full flex flex-row lg:flex-col justify-center items-center gap-x-2 lg:gap-x-0 gap-y-2 flex-wrap p-4">
                  {array.map((item, index) => (
                    <div className="w-full" key={index}>
                      <div
                        className={`cursor-pointer text-center text-green-light`}
                        onClick={() => {
                          setSelectedEventIndex(index);
                        }}
                      >
                        <p
                          className={`${
                            selectedEventIndex === index
                              ? "text-green-dark underline font-bold"
                              : ""
                          } `}
                        >
                          T.A.D.İZMİR ŞUBESİ EĞİTİM DESTEĞİ VERME KRİTERLERİMİZ
                          (2020-2021 ÖĞRETİM YILI)
                        </p>
                        <p className="text-sm">29 Aralık 2021</p>
                      </div>
                      {index !== array.length - 1 ? (
                        <div className="hidden lg:block my-2">
                          <HorizontalDivider />
                        </div>
                      ) : null}
                    </div>
                  ))}
                </div>
              </CardWithRightShadow>
            </div>
          </div>
          <div className="w-full block lg:hidden my-3">
            <HorizontalDivider height={300} />
          </div>
          <div className="flex-1 lg:h-full overflow-y-auto">
            <div className="p-4 lg:p-8">
              <div className="w-[150px] m-auto">
                <img className="w-full h-full" src="/logos/logo.png" />
              </div>
              <div className="my-5">
                <div className="flex flex-col justify-center items-center text-center">
                  <div className="w-[60%]">
                    <p className="text-green-light font-bold">
                      T.A.D.İZMİR ŞUBESİ EĞİTİM DESTEĞİ VERME KRİTERLERİMİZ
                      (2020-2021 ÖĞRETİM YILI)
                    </p>
                    <p className="text-sm text-green-light">29 Aralık 2021</p>
                  </div>
                  <div className="my-2">
                    <p>
                      Dernek <span className="text-green-light">“ana”</span>{" "}
                      olan kadının maddi ve manevi yönden kalkındırılması,
                      eğitilerek aydınlatılması suretiyle çocuğunun Atatürk ilke
                      ve inkılâplarına bağlı topluma yararlı, üretken mutlu
                      birer vatandaş olarak yetiştirilmesini sağlamak amacıyla
                      kurulmuş olup, Türk kadınının sosyal, kültürel, eğitim
                      düzeyini yükseltmek ve çağdaşlaşma yolunda çeşitli
                      çalışmalar yapmakta, Seminer, Panel, Konferans ve Yardım
                      Kampanyası gibi etkinlikler düzenlemektedir. Dernek genel
                      merkezi Ankara ilinde olup, ülkemiz genelinde 58 şubesi
                      ile birlikte faaliyet göstermektedir. Başta deprem
                      bölgelerimizde ki şehirlerimiz olmak üzere  yoğun bir
                      şekilde çeşitli faaliyet ve etkinlikler düzenleyen,
                      yardıma ihtiyacı olana kol kanat geren ve geleceğimizin
                      aydın yüzü olan öğrencilerimize burs imkânları sunan Türk
                      Anneler Derneği, Türkiye’nin pek çok yerinde insanların
                      yaşamlarına dokunuyor.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </BaseModal>
    );
  };

  return (
    <>
      {RenderAnnouncementsModal()}
      <div className="px-8 py-4 md:px-16 md:py-8" id="announcements">
        <LandingTitle
          title={"DUYURULAR"}
          description={
            "Derneğimiz kapsamındaki yenilik, değişiklik ve güncellemelere buradan ulaşabilirsiniz."
          }
        />
        <div className="mt-10">
          <div className="flex flex-row flex-wrap gap-x-2 gap-y-2">
            <div className="w-full lg:w-[560px] lg:h-[560px]">
              {FullItem(
                "T.A.D.İZMİR ŞUBESİ EĞİTİM DESTEĞİ VERME KRİTERLERİMİZ (2020-2021 ÖĞRETİM YILI)",
                "ATATÜRK İLKE VE İNKILAPLARINI benimsemiş, başarılı, çağdaş, sosyal, ST gönüllüsü gençler, karşılıksız burs için Derneğimize başvurularınızı  Eylül 2021 tarihine kadar Dernek e mailine bekliyoruz."
              )}
            </div>
            <div className="flex-1 lg:h-[560px]">
              <div className="h-full flex flex-col gap-y-2">
                {HalfItem(
                  "T.A.D.İZMİR ŞUBESİ EĞİTİM DESTEĞİ VERME KRİTERLERİMİZ (2020-2021 ÖĞRETİM YILI)",
                  "ATATÜRK İLKE VE İNKILAPLARINI benimsemiş, başarılı, çağdaş, sosyal, ST gönüllüsü gençler, karşılıksız burs için..."
                )}
                {HalfItem(
                  "T.A.D.İZMİR ŞUBESİ EĞİTİM DESTEĞİ VERME KRİTERLERİMİZ (2020-2021 ÖĞRETİM YILI)",
                  "ATATÜRK İLKE VE İNKILAPLARINI benimsemiş, başarılı, çağdaş, sosyal, ST gönüllüsü gençler, karşılıksız burs için..."
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="my-5">
          <div className="w-full md:w-1/2 m-auto">
            <div className="flex flex-col justify-center items-center">
              <div
                className="cursor-pointer"
                onClick={() => {
                  setShowModal(true);
                }}
              >
                <p className="underline opacity-50">DAHA FAZLA</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Announcement;
