import Banner from "../components/landing/Banner";
import LandingHeader from "../components/headers/LandingHeader";
import AboutUs from "../components/landing/AboutUs";
import Events from "../components/landing/Events";
import HorizontalDivider from "../components/dividers/HorizontalDivider";
import UsInPress from "../components/landing/UsInPress";
import Announcement from "../components/landing/Announcement";
import Gallery from "../components/landing/Gallery";
import JoinUs from "../components/landing/JoinUs";
import FeePaymentWithIyzico from "../components/landing/FeePaymentWithIyzico";
import LandingFooter from "../components/footers/LandingFooter";
import CardWithLessShadow from "../components/cards/CardWithLessShadow";

const LandingScreen = () => {
  return (
    <div className="">
      <LandingHeader />
      <Banner />
      <div className="px-8 py-4 md:px-16 md:py-8">
        <CardWithLessShadow>
          <AboutUs />
        </CardWithLessShadow>
      </div>
      <Events />
      <div className="px-8 py-4 md:px-16 md:py-8">
        <HorizontalDivider />
      </div>
      <UsInPress />
      <div className="px-8 py-4 md:px-16 md:py-8">
        <HorizontalDivider />
      </div>
      <Announcement />
      <div className="px-8 py-4 md:px-16 md:py-8">
        <HorizontalDivider />
      </div>
      <Gallery />
      <div className="px-8 py-4 md:px-16 md:py-8">
        <HorizontalDivider />
      </div>
      <JoinUs />
      <div className="px-8 py-4 md:px-16 md:py-8">
        <CardWithLessShadow>
          <FeePaymentWithIyzico />
        </CardWithLessShadow>
      </div>
      <div className="py-4 md:py-8">
        <HorizontalDivider />
      </div>
      <LandingFooter />
    </div>
  );
};

export default LandingScreen;
